export const environment = {
    production: true,
    apiUrl: 'https://beta.api.mrscloudconnect.com',
    docsUrl: 'https://beta.docs.mrscloudconnect.com',
    mqttUrl: 'beta.io.mrscloudconnect.com',
    mqttPort: 4083,
    mode: 'default',
    language: 'en',
    aot: true,
    outputHashing: 'all',
    isSpokeZone: false,
    isLenticul: true,
    // TODO - use MRS germany mapId
    mapId: 'ee981fec32fcdfac',
    baseLatitude: 48,
    baseLongitude: 9,
    platformName: 'Cloud Connect',
    version: 'v24.14.0-beta.15',
};
